import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Tokenomics = () => {

  return (
    <section className="token" id="tokenomics">
      <Container>
        <h1> Tokenomics</h1>
        <h2>
      <ul>
      <li>Token supply is 250 million representing the estimated number of depressed people worldwide</li>
      <li>90% of supply will be committed to pool; 10% to the team wallet.</li>
      <li>Ownership renounced</li>
      <li>No taxes</li>
      <li>No inflation</li>
      <li>No presale</li>
      <li>No BS</li>

      <h1>Roadmap</h1>
      <li>Update token information on Etherscan</li>
      <li>Create Uniswap pool</li>
      <li>Lock LP tokens</li>
      <li>List token information on Dexscreener and Sextools</li>
      <li>Vibe and hodl</li>
    </ul>
    </h2>

      </Container>
    </section>
  )
}
