import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
/*
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
*/

import projImg1 from "../assets/img/baby.jpg";
import projImg2 from "../assets/img/monkey.jpg";
import projImg3 from "../assets/img/plan.jpg";
import projImg4 from "../assets/img/biceps.jpg";
import projImg5 from "../assets/img/leolaugh.jpg";
import projImg6 from "../assets/img/cathumbsup.png";
import projImg7 from "../assets/img/saddoge.png";
import projImg8 from "../assets/img/buzz.jpg";
import projImg9 from "../assets/img/buffdoge.png";
import projImg10 from "../assets/img/cheers.jpg";
import projImg11 from "../assets/img/fry.jpg";
import projImg12 from "../assets/img/memecoin.png";
import projImg13 from "../assets/img/butterfly.jpg";
import projImg14 from "../assets/img/trump.jpg";
import projImg15 from "../assets/img/cow.jpg";
import projImg16 from "../assets/img/brain.jpg";
import projImg17 from "../assets/img/depp.jpg";


import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Memes = () => {

  const memes = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg6,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg7,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg8,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg9,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg10,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg11,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg12,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg13,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg14,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg15,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg16,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg17,
    }
  ];

  return (
    <section className="meme" id="memes">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Memes</h2>
                <p></p>
                <Row>
                        {
                          memes.map((meme, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...meme}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
