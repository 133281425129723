import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{`Sad Coin`}</h1>
                
                <span className="tagline">A Coin. A Community. A Movement. A Takeover.</span>
                  <p>
                  $SAD finally gives voice to an underappreciated but massive undercurrent in the memeosphere: sad memes. </p>
                  <p>
                  By sharing relatable memes that poke fun of our hardship, we spread relief and joy.<br />
                  By having a “1 buy = 1 sad” motto, we get to see that we are not alone.<br />
                  By achieving viral status, we raise awareness for the growing prevalence of depression and loneliness.<br />
                  </p>
                  <p>
                  Join the movement. Embrace $SADness.
                  </p>
                  <button onClick={() => console.log('connect')}>Uniswap Coming Soon<ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
